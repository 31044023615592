<template>
    <div class="header-body not">
        <div class="not main">
            <div class="not main left" @click="getHome()">
                <img src="https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/%E6%96%B0-%E7%BB%BF%E8%89%B2logo%E9%80%8F%E6%98%8E.png" alt="logo图" class="logo">
            </div>
            <div class="not main right" v-if="showTitles">
                <div class="not main right title" @click="no(item)" v-for="(item,index) in researchData.fen" :key="index">
                    {{ item.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter,useRoute } from 'vue-router'
import axiosInstance from '~/plusgin/axios';
const router = useRouter();
console.log("router:",router);

const props = defineProps({
    showTitles: {
        type: Boolean,
        default: true, // 默认值为 true
    },
})
const showTitles = computed(() => props.showTitles);

function getHome(){
    
    router.push({ path: '/' });
    // window.location.href = '/';
}

const researchData = ref({
    fen: [{}]
});

function getClassCategory() {
    axiosInstance.get(`/${import.meta.env.VITE_APP_API_PREFIX}/class/no-login/getClassCategory`)
        .then(response => {
            const data = response as unknown as { result: any };
            researchData.value.fen = data.result;
        })
        .catch(error => {
            console.error('获取分类失败', error);
        });
}

onMounted(() => {
    getClassCategory();
});

function no(item: any) {
    if (!item.url) {
        ElMessage({
            message: '暂未开放~',
            type: 'warning',
        });
        return;
    }
    // const urlWithParams = `${item.url}?id=${item.id}`;

    // axiosInstance.get(`/${import.meta.env.VITE_APP_API_PREFIX}/class/no-login/getClassCategoryDetailList`, { params: { id: item.id } })
    //     .then(response => {
    //         const data = response as unknown as { result: any };
    //         console.log('resp:', data.result);
    //     })
    //     .catch(error => {
    //         console.error('获取分类失败', error);
    //     });
    router.push({path:item.url,query:{id:item.id}})

    // window.location.href = urlWithParams;
}
</script>

<style scoped>
.header-body{
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 108px;
}
.header-body .not {
  border-bottom: 1px solid #f5f5f5;
}
.header-body .not{
    display: flex;
    width: 100%;
}
.not .main{
    margin: 0 auto;
    width: 1200px;
    display: flex;
    border: 0px;
}
.logo{
    width: 180px;
    height: 55px;
    border: 0;
    vertical-align: top;
    cursor: pointer;
    object-fit: cover;
}
.not .main .right .title{
    width: 150px;
    line-height: 12px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #333;
    cursor: pointer;
    margin-top: 31px;
    border: 0px;
    z-index: 0;
}
.left .title{
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
}
.main .right{
  flex: 1;
}
.right .title{
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
}
</style>