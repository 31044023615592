<template>
    <el-header class="header-body">
      <div class="header-body top">
        <div class="header-body top main">
          <div class="main left">
            <div class="left title" @click="no(item)" v-for="(item,index) in researchData.left" :key="index">
              <a>{{ item.label }}</a>
            </div>
          </div>
          <div class="main right">
            <div class="right title" @click="no(item)" v-for="(item,index) in researchData.right" :key="index" @mouseover="showQrcode(index)" @mouseout="onMouseOut">
              <a v-if="item.type=='nickName'">
                <template v-if="userInfo.nickName">账号：{{userInfo.nickName}}</template>
                <template v-else>用户注册/登录</template>
              </a>
              <a v-else>{{ item.label }}</a>

              <transition name="fade">
                <div v-if="showQrCode === index && item.qrCode">
                  <img v-if="isImage(item.qrCode)" :src="item.qrCode" alt="QR Code" class="qrcode-img" style="margin-left:-30px;margin-top:50px;position: relative; z-index: 9999" width="95"/>
                  <QRCode v-if="!isImage(item.qrCode)"  :value="item.qrCode" :size="95" level="H" class="qrcode-img" style="margin-left:-30px;margin-top:50px;position: relative; z-index: 9999"/>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </el-header>
</template>
<script lang="ts" setup>
import QRCode from 'qrcode.vue';
import { ref, getCurrentInstance, computed } from 'vue';
import useUserStore from '@/store/modules/user'
import { useRouter } from 'vue-router'
import User from '@/api/entity/User'

const router = useRouter();
const userStore = useUserStore();
const userInfo = computed(()=>User.getInstance(userStore));
const researchData = ref({
  left:[
    { label: '嘉迪课堂',url:'/'},
    { label: '热点资讯',url:'http://www.zkjiadi.cn/news.html'},
    { label: '科研商城',url:'http://www.zkjiadi.cn/shop'},
    { label: '人才智库',url:'https://srs.jksci.cn/adminmrxd/'},
  ],
  right: computed(() => {
    let dynamicItem;
    if (userInfo.value.nickName === null) {
      dynamicItem = { label: null, url: '/user/login.html', type:'nickName'};
    } else {
      dynamicItem = { label: userInfo.value.nickName, type:'nickName'};
    }
    
    return [
      { label: '关注我们',qrCode:'https://zkjiadi-live.oss-cn-heyuan.aliyuncs.com/images/icons/33cf71c5-6a8f-4ced-bd8a-be389df42406_gongzonghaologo.png'},
      { label: '移动端' ,url:null,qrCode:'http://m.test-live.zkjiadi.cc/'},
      // { label: '商家登录'},
      // { label: '商家入驻' },
      dynamicItem
    ];
  })
});

//传入地址判断是否为图片url
function isImage(url:any) {
  // 判断 URL 是否是图片
  return /\.(png|jpg|jpeg|gif|bmp)$/.test(url);
}

userStore.$onAction(
  ({
    name, // action 名称
    store, // store 实例，类似 `someStore`
    args, // 传递给 action 的参数数组
    after, // 在 action 返回或解决后的钩子
    onError, // action 抛出或拒绝的钩子
  }) => {
    // 为这个特定的 action 调用提供一个共享变量
    after((result) => {
    })
  }
)

function no(item:any) {
  if(item.qrCode){
    return;
  }
  if(item.type=='nickName' && userInfo.value.nickName) {
    useUserStore().logOut();
    return;
  }
  if (!item.url) {
    ElMessage({
      message: '暂未开放~',
      type: 'warning',
    });
    return;
  }

  // 检查即将跳转的 url 是否与当前页面相同
  if (route.path === item.url) {
    return;
  }

  // 判断 URL 是否以 'http' 开头，如果是，则在新窗口打开
  if (item.url.startsWith('http')) {
    window.open(item.url, '_blank');
  } else {
    // 否则在当前页面打开
    router.push({path:item.url})
  }
}

function getHome(){
  router.push({ path: '/' });
}

const route = useRoute(); // 获取当前的路由信息

const showQrCode  = ref(-1)

function showQrcode(index:number) {
    showQrCode.value = index;
}
function onMouseOut() {
    showQrCode.value = -1;
}
</script>